<template>
  <BaseAuth
    title="Sign In"
    context="signin"
  >
    <template v-slot:before_actionable_text>
      <p v-if="false">
        Do not have an account?
        <a
          href="/signup"
          class="text-bb-brand-purple font-bold cursor-pointer"
          >Sign up</a
        >
      </p>
    </template>
  </BaseAuth>
</template>

<script>
import BaseAuth from './BaseAuth.vue'

export default {
  name: 'signIn',
  components: {
    BaseAuth,
  },
}
</script>
