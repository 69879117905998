<template>
  <div id="buttonDiv"></div>
</template>

<script>
import googleConfig from '@/configs/google-client'

export default {
  name: 'GoogleSignInButton',
  metaInfo() {
    return {
      script: [{ src: 'https://accounts.google.com/gsi/client', async: true }],
    }
  },
  props: {
    context: {
      type: String,
    },
    oneTap: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async initGoogle() {
      window.onGoogleLibraryLoad = () => {
        window.google.accounts.id.initialize({
          client_id: googleConfig.client_id,
          callback: this.signedInCallback,
          context: this.context,
          ux_mode: 'popup',
          auto_prompt: this.oneTap,
          auto_select: false,
          cancel_on_tap_outside: false,
        })

        if (this.oneTap) {
          window.google.accounts.id.prompt()
        }

        window.google.accounts.id.renderButton(document.getElementById('buttonDiv'), {
          theme: 'filled_blue',
          size: 'large',
          type: 'standard',
          text: this.context === 'signin' ? 'signin_with' : 'signup_with',
          logo_alignment: 'left',
        })
      }
    },
    async signedInCallback(response) {
      this.$emit('signedInWithGoogle', response)
    },
  },
  async mounted() {
    await this.initGoogle()
  },
}
</script>
