<template>
  <div>
    <OnboardingLayout>
      <div v-if="!isLoggedIn">
        <h3>{{ title }}</h3>
        <slot name="before_actionable_text" />
        <div class="my-6">
          <GoogleSignInButton
            :context="context"
            @signedInWithGoogle="signInWithGoogleCallback"
          />
        </div>
        <div class="flex gap-1 items-center">
          <check-box
            :class="{ shake: shakeCheckbox.policy }"
            input-id="policy-agreement"
            input-name="policy[agreed]"
            :value="agreePolicy"
            @input="agreePolicy = !agreePolicy"
          />
          <div>
            <p>
              I agree to BrightBid
              <router-link
                :to="{ name: 'privacy:policy' }"
                class="text-bb-brand-purple font-bold cursor-pointer"
                >Privacy policy
              </router-link>
            </p>
          </div>
        </div>
        <div class="flex gap-1 items-center">
          <check-box
            :class="{ shake: shakeCheckbox.usage }"
            input-id="usage-agreement"
            input-name="usage[agreed]"
            :value="agreeUsage"
            @input="agreeUsage = !agreeUsage"
          />
          <div>
            <p>
              I agree to BrightBid using my data for in third-party tools,
              <router-link
                :to="{ name: 'limited:use:policy' }"
                class="text-bb-brand-purple font-bold cursor-pointer"
                >read about our limited use policy.
              </router-link>
            </p>
          </div>
        </div>
        <p
          v-if="$v.agreeUsage.$error || $v.agreePolicy.$error"
          class="text-bb-error"
        >
          You must agree to the Privacy and Use Policy.
        </p>
      </div>
      <div v-else>
        <form @keydown.prevent.enter="saveContactInfo">
          <div class="contact-info-form">
            <h3 class="mb-6">Contact Info</h3>
            <div class="flex gap-5 items-center mb-10">
              <text-input
                v-model="contactInfoForm.firstName"
                class="w-full"
                label="First Name"
                input-type="text"
                input-name="first-name"
                input-id="first-name"
                placeholder="Name"
                :error-message="$v.contactInfoForm.firstName.$error ? 'Please enter a name' : null"
                @focusout="$v.contactInfoForm.firstName.$touch"
              />

              <text-input
                v-model="contactInfoForm.lastName"
                class="w-full"
                label="Last Name"
                input-type="text"
                input-name="last-name"
                input-id="last-name"
                placeholder="Last Name"
                :error-message="$v.contactInfoForm.lastName.$error ? 'Please enter a name' : null"
                @focusout="$v.contactInfoForm.lastName.$touch"
              />
            </div>
            <MergeButtonRound
              class="w-full"
              @click="saveContactInfo"
            >
              Confirm
            </MergeButtonRound>
          </div>
        </form>
      </div>
    </OnboardingLayout>
  </div>
</template>

<script>
import CheckBox from '@/components/input/brightbid/CheckBox'
import OnboardingLayout from '@/layouts/onboarding'
import TextInput from '@/components/input/brightbid/TextInput'
import GoogleSignInButton from '@/components/btn/Google/GoogleSignInButton.vue'
import Toast from '@/components/shared/Toast'

import { required, sameAs } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { ONBOARDING_WIZARD_STATE_KEY } from '@/core/machines/onboarding-wizard'
import { AGREEMENTS } from '@/utils/enums'

export default {
  name: 'BaseAuth',
  components: {
    GoogleSignInButton,
    CheckBox,
    OnboardingLayout,
    TextInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      agreePolicy: this.getConsent(AGREEMENTS.POLICY),
      agreeUsage: this.getConsent(AGREEMENTS.USAGE),
      shakeCheckbox: {
        policy: false,
        usage: false,
      },
      expectedCheckboxValue: true,
      isNewUser: false,
      error: null,
      isLoggedIn: false,
      contactInfoForm: {
        firstName: null,
        lastName: null,
      },
    }
  },
  computed: {
    utmData() {
      return {
        utm_source: this.$route.query.utm_source || 'undefined',
        utm_medium: this.$route.query.utm_medium || 'undefined',
        utm_campaign: this.$route.query.utm_campaign || 'undefined',
        utm_content: this.$route.query.utm_content || 'undefined',
      }
    },

    isProduction() {
      return window.location.host.startsWith('app.brightbid')
    },
  },
  validations() {
    return {
      contactInfoForm: {
        firstName: { required },
        lastName: { required },
      },
      agreePolicy: {
        required,
        sameAsExpected: sameAs('expectedCheckboxValue'),
      },
      agreeUsage: {
        required,
        sameAsExpected: sameAs('expectedCheckboxValue'),
      },
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
      signOut: 'auth/signOut',
      additionalSignup: 'auth/additionalSignup',
    }),
    async mounted() {
      // Google Tag Manager
      this.$gtm.enable(this.isProduction)
    },
    getConsent(key) {
      return localStorage.getItem('consent') ? JSON.parse(localStorage.getItem('consent'))[key] : false
    },
    shakeCheckboxNow(checkboxName) {
      // Check if the checkboxName is valid
      if (!Object.prototype.hasOwnProperty.call(this.shakeCheckbox, checkboxName)) {
        return
      }

      // Shake the checkbox
      this.shakeCheckbox[checkboxName] = true
      setTimeout(() => (this.shakeCheckbox[checkboxName] = false), 1000)
    },

    async signInWithGoogleCallback(response) {
      // Validate the checkboxes before proceeding
      this.$v.$touch()
      if (this.$v.agreePolicy.$error) {
        this.shakeCheckboxNow('policy')
      }
      if (this.$v.agreeUsage.$error) {
        this.shakeCheckboxNow('usage')
      }
      if (this.$v.agreePolicy.$error || this.$v.agreeUsage.$error) {
        return
      }

      if (this.googleAdsAccess) {
        this.$gtm.trackEvent({
          event: 'connect_store_open_view',
        })
        return
      }

      // Register
      try {
        let formData = {
          token: response.credential,
        }
        let utm_cookie = document.cookie.match('(^|;)\\s*' + 'utm' + '\\s*=\\s*([^;]+)')?.pop() || ''

        const consent = {
          [AGREEMENTS.USAGE]: this.agreeUsage,
          [AGREEMENTS.POLICY]: this.agreePolicy,
        }

        localStorage.setItem('consent', JSON.stringify(consent))

        if (utm_cookie) {
          let parsedUtm = JSON.parse(utm_cookie)
          formData.utm_source = parsedUtm.utm_source || 'undefined'
          formData.utm_medium = parsedUtm.utm_medium || 'undefined'
          formData.utm_campaign = parsedUtm.utm_campaign || 'undefined'
          formData.utm_content = parsedUtm.utm_content || 'undefined'
        } else {
          formData.utm_source = this.utmData.utm_source
          formData.utm_medium = this.utmData.utm_medium
          formData.utm_campaign = this.utmData.utm_campaign
          formData.utm_content = this.utmData.utm_content
        }

        // Deletes cookie when used
        document.cookie = 'utm' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'

        const data = await this.signIn(formData)
        if (!data) return
        this.isNewUser = data.is_new_user

        if (this.isNewUser) {
          this.isLoggedIn = true
        } else {
          await this.$router.push({
            name: 'portfolio',
          })
        }

        this.contactInfoForm = {
          firstName: data.user.given_name,
          lastName: data.user.family_name,
        }

        this.$gtm.trackEvent({
          event: 'sign_in',
          project: 'console',
        })
      } catch (error) {
        console.warn(error)
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to Log In. Please try again',
            type: 'error',
          },
        })
      }
    },
    async saveContactInfo() {
      this.$v.contactInfoForm.$touch()
      if (this.$v.contactInfoForm.$invalid || !this.isNewUser) return

      await this.additionalSignup({ ...this.contactInfoForm }).catch(e => (this.error = e))

      if (this.error) return

      localStorage.removeItem(ONBOARDING_WIZARD_STATE_KEY)
      await this.$router.push({
        name: 'onboarding-wizard',
      })
    },
  },
}
</script>

<style scoped lang="scss">
.contact-info-form {
  width: 380px;
}
</style>
