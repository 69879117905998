import { assign, createMachine } from 'xstate'

export const STATES = {
  WELCOME: 'WELCOME',
  VIEW_OR_CREATE_ORGS: 'VIEW_OR_CREATE_ORGS',
  CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
  CREATE_SITE_INITIAL: 'CREATE_SITE_INITIAL',
  CREATE_SITE_WEBSITE: 'CREATE_SITE_WEBSITE',
  SETUP_CONNECTIONS: 'SETUP_CONNECTIONS',
  SETUP_TARGET_GOALS: 'SETUP_TARGET_GOALS',
}

export const EVENTS = {
  NEXT: 'NEXT',
  PREV: 'PREV',
}

export const ONBOARDING_WIZARD_STATE_KEY = 'onboarding-wizard-state-key'

// State machine
const onboardingWizardMachine = createMachine(
  {
    id: 'onboarding',
    initial: STATES.WELCOME,
    context: {
      organizationName: null,
      siteURL: null,
      siteName: null,
      siteCountry: null,
      siteLanguage: null,
      isWebsite: null,
      target: null,
      businessDescription: null,
      industry: null,
      organizationID: null,
      siteID: null,
      desiredState: null,
    },
    states: {
      [STATES.WELCOME]: {
        on: { [EVENTS.NEXT]: STATES.VIEW_OR_CREATE_ORGS },
      },
      [STATES.VIEW_OR_CREATE_ORGS]: {
        on: { [EVENTS.NEXT]: STATES.CREATE_ORGANIZATION },
      },
      [STATES.CREATE_ORGANIZATION]: {
        on: {
          [EVENTS.NEXT]: {
            target: STATES.CREATE_SITE_INITIAL,
            actions: ['assignOrganizationName'],
          },
          [EVENTS.PREV]: STATES.VIEW_OR_CREATE_ORGS,
        },
      },
      [STATES.CREATE_SITE_INITIAL]: {
        on: {
          [EVENTS.NEXT]: [
            {
              cond: 'isSiteWebsite',
              target: STATES.CREATE_SITE_WEBSITE,
            },
            {
              target: STATES.SETUP_CONNECTIONS,
            },
          ],
          [EVENTS.PREV]: STATES.CREATE_ORGANIZATION,
        },
        exit: ['assignSiteDetails'],
      },
      [STATES.CREATE_SITE_WEBSITE]: {
        on: {
          [EVENTS.NEXT]: STATES.SETUP_CONNECTIONS,
          [EVENTS.PREV]: STATES.CREATE_SITE_INITIAL,
        },
        exit: ['assignSiteSetup'],
      },
      [STATES.SETUP_CONNECTIONS]: {
        on: {
          [EVENTS.NEXT]: [STATES.SETUP_TARGET_GOALS],          
          [EVENTS.PREV]: [
            {
              cond: 'isWebsite',
              target: STATES.CREATE_SITE_WEBSITE,
            },
            {
              cond: 'isAmazon',
              target: STATES.CREATE_SITE_INITIAL,
            },
          ],
        },
      },
      [STATES.SETUP_TARGET_GOALS]: {
        on: {
          [EVENTS.PREV]: STATES.SETUP_CONNECTIONS
        },
      },
    },
  },
  {
    actions: {
      assignOrganizationName: assign({
        organizationName(context, event) {
          if (event.type === 'PREV' || !event.payload) {
            return context.organizationName || null
          }
          return event.payload.name
        },
        organizationID(context, event) {
          if (!event.payload) return context.organizationID
          return event.payload.organizationID
        },
      }),
      assignSiteDetails: assign({
        isWebsite(context, event) {
          if (!event.payload) return true
          return event.payload.websiteOrAmazon === 'website'
        },
        siteName(context, event) {
          if (!event.payload) return context.siteName
          return event.payload.siteName
        },
        siteCountry(context, event) {
          if (!event.payload) return context.siteCountry
          return event.payload.siteCountry
        },
        siteLanguage(context, event) {
          if (!event.payload) return context.siteLanguage
          return event.payload.siteLanguage
        },
        siteURL(context, event) {
          if (!event.payload) return context.siteURL
          return event.payload.siteUrl
        },
      }),
      assignSiteSetup: assign({
        target(context, event) {
          // default to B2B
          if (!event.payload) return ['B2B']
          return event.payload.target
        },
        businessDescription(context, event) {
          if (!event.payload) return context.businessDescription
          return event.payload.businessDescription
        },
        industry(context, event) {
          if (!event.payload) return context.industry
          return event.payload.industry
        },
        siteID(context, event) {
          if (!event.payload) return context.siteID
          return event.payload.siteID
        },
      }),
    },
    guards: {
      isSiteWebsite(context, event) {
        if (!event.payload) return null
        return event.payload.websiteOrAmazon === 'website'
      },
      isAmazon(context) {
        return !context.isWebsite
      },
      isWebsite(context) {
        return context.isWebsite
      },
    },
  },
)

export default onboardingWizardMachine
