import { render, staticRenderFns } from "./BaseAuth.vue?vue&type=template&id=18c95300&scoped=true"
import script from "./BaseAuth.vue?vue&type=script&lang=js"
export * from "./BaseAuth.vue?vue&type=script&lang=js"
import style0 from "./BaseAuth.vue?vue&type=style&index=0&id=18c95300&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c95300",
  null
  
)

export default component.exports